<template>
  <div id="app" :class="[{ scrolled: scrolled }]">
    <div v-if="init" :class="[menuActive ? 'menu-open' : '', 'main']">
      <div id="nav" :class="[{ scrolled: scrolled }]">
        <div class="col-1" />
        <div class="nav-content-container">
          <div
            class="logo-container"
            @click="$router.push('/').catch((err) => {})"
          >
            <img src="@/assets/logo.png" alt="André Siebdruck Logo" />
          </div>
          <div class="burger-button-container">
            <img
              src="@/assets/burger-button.png"
              role="button"
              @click="toggleMenu"
              alt="Burger Button"
            />
          </div>
          <div class="title-container">
            <template v-if="$route.meta.title || (curPage && curPage.title)">
              <h1>
                {{
                  $route.meta.title ? $route.meta.title : curPage.title.rendered
                }}
              </h1>
            </template>
            <template v-else>
              <h1>Willkommen</h1>
            </template>
          </div>
        </div>
        <div class="col-2" />
      </div>
      <div class="content-container">
        <div class="col-1" />
        <div class="router-view">
          <transition name="fade-page" mode="out-in">
            <router-view />
          </transition>
          <router-link
            v-if="!$route.meta.hideSpecialLink"
            class="action-link"
            to="/aktion"
          >
            <img src="@/assets/aktion.png" alt="Aktion" />
          </router-link>
        </div>
        <div class="col-2" />
      </div>
      <div class="footer-container">
        <div class="col-1" />
        <TheFooter />
        <div class="col-2" />
      </div>
      <transition name="menu">
        <TheMenu v-if="menuActive" />
      </transition>
    </div>
    <div v-else class="loader">
      <div>
        <transition name="fade" appear>
          <img src="@/assets/logo.png" alt="André Siebdruck Logo" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import TheFooter from "@/components/TheFooter";
import TheMenu from "@/components/TheMenu";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    TheFooter,
    TheMenu,
  },
  data() {
    return {
      init: true,
      scrolled: false,
      loaded: false,
    };
  },
  watch: {
    loaded(n) {
      if (n) {
        document.dispatchEvent(new Event("render-event"));
      }
    },
  },
  async created() {
    let vm = this;
    window.addEventListener("scroll", () => {
      if (vm.scrolled && window.scrollY < 140) vm.scrolled = false;
      if (!vm.scrolled && window.scrollY > 200) vm.scrolled = true;
    });
    await Promise.all([
      this.loadPages(),
      this.loadSettings(),
      this.loadProducts(),
      this.loadReferences(),
    ]);
    this.loaded = true;
  },
  computed: {
    ...mapGetters(["settings", "curPage", "menuActive"]),
  },
  methods: {
    ...mapActions([
      "loadSettings",
      "loadPages",
      "loadProducts",
      "loadReferences",
      "loadSettings",
      "toggleMenu",
    ]),
  },
};
</script>

<style lang="scss">
/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/roboto-v30-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("/fonts/roboto-v30-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/roboto-v30-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/roboto-v30-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("/fonts/roboto-v30-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/fonts/roboto-v30-latin-300.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/roboto-v30-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("/fonts/roboto-v30-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/roboto-v30-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/roboto-v30-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("/fonts/roboto-v30-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/fonts/roboto-v30-latin-regular.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/roboto-v30-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("/fonts/roboto-v30-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/roboto-v30-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/roboto-v30-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("/fonts/roboto-v30-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/fonts/roboto-v30-latin-500.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/roboto-v30-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("/fonts/roboto-v30-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/roboto-v30-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/roboto-v30-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("/fonts/roboto-v30-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/fonts/roboto-v30-latin-700.svg#Roboto")
      format("svg"); /* Legacy iOS */
}

:root {
  --red: rgba(227, 28, 28, 1);
  --grey: rgba(101, 102, 101, 1);
  --grey-menu: rgba(101, 102, 101, 0.9);
  --light-grey: rgba(96, 100, 103, 1);
  --brown: rgba(131, 118, 88, 1);
}

html {
  height: 100%;
  margin: 0;
}

body {
  background: var(--grey, grey);
  color: var(--grey, grey);
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  height: 100%;
  margin: 0;
}

div,
p {
  box-sizing: border-box;
}

figure {
  margin: 0;
}

figcaption {
  margin-top: 12px;
}

hr {
  height: 1px;
  background: transparent;
  border: none;
  border-top: 2px solid var(--red);
  margin: 60px 0;
  @media all and (max-width: 600px) {
    margin: 20px 0;
  }
}

a {
  text-decoration: none;
  color: var(--red, red);
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  text-transform: uppercase;
  color: var(--brown);
  font-weight: 500;
}

.has-text-align-center {
  text-align: center;
}

.start-page-multi-nav {
  img {
    margin-bottom: 20px;
  }
}

p {
  margin: 10px 0;
  font-weight: 300;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    position: relative;
    margin-left: 32px;
    &::before {
      color: var(--red);
      content: "\2022";
      display: inline-block;
      font-size: 1.5em;
      /* use em or % */
      left: -0.65em;
      /* use em, line up bullet flush with left hand side */
      position: absolute;
      top: -0.03em;
    }
  }
}

iframe {
  width: 100%;
}

.aligncenter {
  text-align: center;
}

.alignleft {
  text-align: left;
}

.alignright {
  text-align: right;
}

.margin-top {
  margin-top: 30px;
}

#app {
  margin: 0 auto;
  /*margin-left: 200px;*/
  height: 100%;
  /*transition: padding-top 0.3s ease-in-out*/

  @media all and (max-width: 400px) {
    &.scrolled {
      padding-top: 200px;
    }
  }

  .main {
    .nav,
    .content-container {
      /*filter: none;*/
      /*transition: filter 0.3s ease-in-out;*/
    }

    &.menu-open {
      .nav,
      .content-container {
        /*filter: blur(5px);*/
      }
    }
  }
}

.col-1,
.col-2 {
  @media all and (max-width: 1360px) {
    min-width: 60px;
  }
  @media all and (max-width: 768px) {
    min-width: 20px;
    max-width: 20px;
  }
  @media all and (max-width: 400px) {
    min-width: 5px;
    max-width: 5px;
  }
}
.col-1 {
  /*background: #00ff00;*/
}
.col-2 {
  /*background: #0000ff;*/
}

#nav {
  display: flex;
  height: 270px;
  /*padding-top: 60px;*/
  /*transition: all 0.3s ease-in-out;*/

  @media all and (max-width: 400px) {
    height: 200px;
    padding-left: 20px;

    &.scrolled {
      padding-top: 0;
    }
  }

  .col-1 {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .col-2 {
    flex-grow: 1;
  }

  .nav-content-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 1240px;
    /*@media all and (max-width: 1130px) {*/
    /*width: unset;*/
    /*}*/

    @media all and (max-width: 600px) {
      justify-content: center;
    }

    @media all and (max-width: 400px) {
      justify-content: center;
    }

    .logo-container {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      height: 160px;
      flex: 0 0 auto;
      /*margin-top: 60px;*/
      /*transition: all 0.3s ease-in-out;*/
      @media all and (max-width: 700px) {
        width: 110px;
        height: 110px;
      }

      @media all and (max-width: 400px) {
        width: 100px;
        height: 100px;
        margin: 0 auto;
      }

      &:after {
        content: "";
        position: absolute;
        box-sizing: border-box;
        border: 1px solid var(--red);
        border-radius: 50%;
        width: 99%;
        height: 99%;
      }

      &:hover {
        &:after {
          animation: logo-hover-animation 1s;
        }
      }
    }

    .burger-button-container {
      color: #fff;
      display: flex;
      align-items: center;
      align-self: center;
      margin: 0 50px 0 60px;
      user-select: none;
      width: 40px;
      height: 40px;
      flex: 0 0 auto;

      @media all and (max-width: 700px) {
        margin: 0 30px 0 40px;
      }

      @media all and (max-width: 500px) {
        margin: 0 15px 0 20px;
      }

      @media all and (max-width: 400px) {
        margin: 0 10px 0 0;
        width: 30px;
        height: 30px;
      }

      img {
        max-width: 100%;
        height: auto;
        cursor: pointer;
      }
    }

    .title-container {
      display: flex;
      align-items: center;
      flex: 0 1 auto;
      @media all and (max-width: 400px) {
        display: none;
      }

      h1 {
        padding-left: 20px;
        text-transform: uppercase;
        padding-right: 20px;
        display: inline-block;
        margin: 0;
        font-weight: 300;
        text-align: right;
        font-size: 40px;
        color: #fff;
      }

      @media all and (max-width: 900px) {
        font-size: 35px;
      }
      @media all and (max-width: 700px) {
        h1 {
          font-size: 30px;
          padding: 0 10px;
        }
      }
      @media all and (max-width: 600px) {
        h1 {
          font-size: 25px;
          padding: 0 10px;
        }
      }
      @media all and (max-width: 500px) {
        h1 {
          font-size: 20px;
          padding: 0 5px;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  @media all and (max-width: 400px) {
    top: -60px;
    transition: top 0.3s ease-in-out;

    &.scrolled {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 60px;
      background: var(--grey);
      z-index: 1;
      box-shadow: 0px 1px 10px #525252ad;

      .col-1 {
        display: none;
      }

      .nav-content-container {
        align-items: center;
        justify-content: center;

        .logo-container {
          width: 50px;
          height: 50px;
          margin: 0 auto;
        }
      }
    }
  }
}

.content-container {
  background: #fff;
  display: flex;

  .col-1 {
    background: var(--grey);
    flex-grow: 1;
    flex-shrink: 1;
  }
  .col-2 {
    background: #fff;
    flex-grow: 1;
  }

  .router-view {
    width: 1240px;
    background: #fff;
    padding: 60px 40px 60px 40px;
    /*border: 1px solid #0000ff;*/
    position: relative;

    @media all and (max-width: 1130px) {
      width: 100%;
      flex-shrink: 1;
    }
    @media all and (max-width: 900px) {
      width: 100%;
    }
    @media all and (max-width: 400px) {
      width: 100%;
      overflow: hidden;
    }

    /*&:before {*/
    /*width: calc(50%);*/
    /*height: 100%;*/
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 0;*/
    /*background: yellow;*/
    /*opacity: 0.6;*/
    /*z-index: 10;*/
    /*content: '';*/
    /*}*/
    /*&:after {*/
    /*width: 50%;*/
    /*height: 100%;*/
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 50%;*/
    /*background: orange;*/
    /*opacity: 0.6;*/
    /*z-index: 10;*/
    /*content: '';*/
    /*}*/

    .action-link {
      position: sticky;
      display: block;
      margin-top: 30px;
      margin-left: -100px;
      bottom: 30px;
      width: 160px;

      @media all and (max-width: 768px) {
        width: 60px;
        overflow: hidden;
        bottom: 60px;
        margin-left: -60px;
        display: none;

        img {
          width: auto;
          max-width: none;
        }
      }
    }

    @media all and (max-width: 600px) {
      padding: 10px;
    }
  }
}

.wp-block-image {
  img {
    max-width: 100%;
    height: auto;
  }
}

.wp-block-columns {
  display: flex;
  @media all and (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .wp-block-column {
    width: 50%;

    p:first-child {
      margin-top: 0;
    }

    @media all and (max-width: 600px) {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    &:not(:first-child) {
      margin-left: 32px;
      @media all and (max-width: 600px) {
        margin-left: 0;
      }
    }
    @media all and (max-width: 400px) {
      width: 100%;
    }
  }

  &.has-2-columns {
    &.width-1-2 {
      .wp-block-column:first-child {
        max-width: 460px;
      }
      .wp-block-column:last-child {
        width: 660px;
        @media all and (max-width: 600px) {
          width: 100%;
        }
        @media all and (max-width: 400px) {
          width: 100%;
          padding: 5px;
        }
      }
    }
    &.width-2-1 {
      .wp-block-column:last-child {
        max-width: 460px;
      }
      .wp-block-column:first-child {
        width: 660px;
      }
    }
    .wp-block-column {
      width: 50%;
      @media all and (max-width: 600px) {
        width: 100%;
      }
    }
  }

  &.has-3-columns {
    .wp-block-column {
      width: 33.33%;
      @media all and (max-width: 600px) {
        width: 100%;
      }
    }
  }
}

.wp-block-gallery {
  ul.blocks-gallery-grid {
    position: relative;
    li {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    position: relative;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: auto;
      /*z-index: 2;*/
    }

    /*&:before {*/
    /*width: 180px;*/
    /*height: 180px;*/
    /*content: '';*/
    /*position: absolute;*/
    /*border-radius: 50%;*/
    /*border-width: 2px;*/
    /*border-top-color: white;*/
    /*border-left-color: green;*/
    /*border-bottom-color: blue;*/
    /*border-right-color: yellow;*/
    /*}*/

    &:after {
      content: "";
      position: absolute;
      border: 2px solid var(--red);
      width: 180px;
      height: 180px;
      border-radius: 50%;
      animation: circle-animation 2s infinite;
      /*background: var(--red);*/
      /*z-index: 1;*/
    }
  }
}

.footer-container {
  display: flex;

  .col-1 {
    flex-grow: 1;
    flex-shrink: 1;
    width: 60px;
  }

  .col-2 {
    flex-grow: 1;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 1s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.fade-page-enter-active,
.fade-page-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-page-enter,
.fade-page-leave-active {
  opacity: 0;
}

.menu-enter-active,
.menu-leave-active {
  transition-duration: 0.3s;
  /*transition-property: opacity;*/
  transition-property: all;
  transition-timing-function: ease;
  .menu {
    transition: all 0.3s ease-in-out;
  }
}

.menu-enter,
.menu-leave-active {
  opacity: 0;
  .menu {
    transform: scale(0);
  }
}

@keyframes circle-animation {
  0% {
    width: 120px;
    height: 120px;
    opacity: 1;
    border-width: 10px;
  }
  25% {
    opacity: 0.8;
  }
  100% {
    width: 240px;
    height: 240px;
    opacity: 0;
    border-width: 1px;
  }
}

@keyframes logo-hover-animation {
  0% {
    width: 99%;
    height: 100%;
    opacity: 1;
    border-width: 10px;
  }
  25% {
    opacity: 0.8;
  }
  100% {
    width: 120%;
    height: 120%;
    opacity: 0;
    border-width: 1px;
  }
}

#ruler {
  position: fixed;
  height: 100%;
  width: 1px;
  background: #00ff00;
  top: 0;
  left: 50%;
}
</style>
