<template>
  <div class="references-view">
    <div class="references-container">
      <div v-for="reference, index of references" class="reference">
        <div class="gallery-contaienr" v-if="reference.gallery">
          <Gallery :items="reference.gallery" :interval="5000" :delay="index * 1000" :automatic="true" />
        </div>
        <div class="title">
          <DynamicContent :content="reference.content.rendered" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DynamicContent from '@/components/DynamicContent';
import Gallery from '@/components/Gallery';

export default {
  name: "References",
  components: {
    DynamicContent,
    Gallery
  },
  computed: {
    ...mapGetters([ "references", "defaultKeywords" ])
  },
  metaInfo() {
    let refKeywords = this.references.map(r => r.title.rendered + ", " + r.excerpt.rendered.replace(/(<([^>]+)>)/ig,"").trim());
    refKeywords = [...refKeywords, this.defaultKeywords];
    return {
      title: "André Siebruck und Werbemittel: Referenzen - Wir stehen für Qualität",
      meta: [
        { vmid: "keywords", name: "keywords", content: refKeywords.join(",")},
        {
          vmid: "description",
          name: "description",
          content: "André Siebruck und Werbemittel: Wir stehen für Qualität. Lassen Sie sich von unseren Referenzen überzeugen."
        }
      ]
    };
  }
}
</script>

<style lang="scss" scoped>
.references-view {
  .references-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .reference {
      width: 560px;
      margin-bottom: 20px;

      @media all and (max-width: 600px) {
        width: 100%;
      }

      .title {
        width: 100%;
        font-size: 20px;
      }

      .gallery-container {
        width: 100%;
      }
    }
  }
}
</style>
