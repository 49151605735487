<template>
  <div class="gallery-container">
    <transition name="transition">
      <img :src="image" class="current" :key="'logo-' + current" alt="Aktuelles Bild"/>
    </transition>
    <img :src="image" class="placeholder" alt="Platzhalter"/>
    <div v-if="controls" class="gallery-btn prev" @click="prev">&lt;</div>
    <div v-if="controls" class="gallery-btn next" @click="next">&gt;</div>
    <div v-if="controls" class="dots-container">
      <div 
        v-for="image, index of items"
        :class="{ active: current == index }" 
        class="dot"
        @click="current = index"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Gallery",
  props: {
    items: Array,
    interval: Number,
    delay: Number,
    automatic: Boolean
  },
  data() {
    return {
      current: 0,
      timerId: null
    }
  },
  computed: {
    controls() {
      if (this.items && this.items.length > 1 && !this.automatic) return true;
    },
    image() {
      if (this.items.length > this.current) return this.items[this.current];
    }
  },
  mounted() {
    if (this.interval) {
      if (this.delay) {
        let that = this;
        setTimeout(() => setInterval(that.next, that.interval), that.delay);
      } else {
        this.timerId = setInterval(this.next, this.interval);
      }
    }
  },
  beforeDestroy() {
    if (this.timerId) clearTimeout(this.timerId);
  },
  methods: {
    next() {
      let next = (this.current + 1 < this.items.length) ? this.current++ : this.current = 0;
    },
    prev() {
      let next = (this.current - 1 > 0) ? this.current-- : this.current = this.items.length - 1;
    }
  }
}
</script>

<style lang="scss" scoped>
.gallery-container {
  position: relative;
  display: inline-block;
  padding: 0;
  overflow: hidden;
  width: 100%;

  .placeholder {
    opacity: 0;
  }

  img.current {
    width: 100%;
    height: auto;
    object-fit: cover;
    position: absolute;
  }

  .gallery-btn {
    position: absolute;
    top: calc(50% - 20px);
    width: 30px;
    height: 30px;
    background: var(--red);
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 2px 2px 15px #0000007a;
    opacity: 0.5;
    transition: opacity 0.5s ease-in-out;

    &.prev {
      left: 5px;
    }
    &.next {
      right: 5px;
    }
  }

  .dots-container {
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    opacity: 0.5;
    transition: opacity 0.5s ease-in-out;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--grey);
      margin: 0 5px;
      cursor: pointer;

      &.active, &:hover {
        background: var(--red);
      }
    }
  }

  &:hover {
    .gallery-btn, .dots-container {
      opacity: 1;
    }
  }
}

.transition-enter-active, .transition-leave-active {
  position: absolute;
  transition: all 1.0s;
}
.transition-enter, .transition-leave-to /* .transition-leave-active below version 2.1.8 */ {
  opacity: 0;
  /*transform: translateX(100px);*/
}
</style>
