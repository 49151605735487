<script>
export default {
  name: 'DynamicContent',
  render(el) {
    return el('component', { is: this.transformed });
  },
  props: {
    content: {
      type: String,
      default: ''
    },
    inline: {
      type: Boolean,
      default: false
    },
    transition: {
      type: String,
      default: "page-fade"
    }
  },
  computed: {
    transformed () {
      let domain = process.env.VUE_APP_WP_API_URL.replace(/http(s)?:\/\//, '');
      let exp = new RegExp("<a ?([\\w-]*=\".*\")? ?href=\"(http|https?:\\/\\/" + domain + "(\\/)?([\\w-_\\/]*))\"( ?[\\w-]*=\".*\")?>(.*)(<\\/a>)", 'gi');
      let text = this.content.replace(exp, '<router-link $1to="/$4"$5>$6</router-link>');
      let relExp = new RegExp("<a ?([\\w-]*=\".*\")? ?href=\"\\/([\\w-_\\/]*)\"( ?[\\w-]*=\".*\")?>(.*)(<\\/a>)", 'gi');
      text = text.replace(relExp, '<router-link $1to="/$2"$3>$4</router-link>');
      if (!this.inline && this.transition != "") {
        text = '<transition name="page-fade"><div class="dynamic-content">' + text + '</div></transition>';
      } else {
        text = "<span>" + text + "</span>";
      }
      return { template: text }
    }
  }
}
</script>
