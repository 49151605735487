<template>
  <div class="base-page">
    <transition name="fade-page" mode="out-in" appear>
    <template v-if="page && multipage">
      <MultiPage :main-page="page" :pages="page.pages" :subPage="$route.params.slug" :base="base" />
    </template>
    <template v-else-if="page">
      <DynamicContent :content="page.content.rendered" />
    </template>
    <div v-else>
      <h1>404 - Hoppala!</h1>
      <p>Seite nicht gefunden :(</p>
    </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DynamicContent from '@/components/DynamicContent';
import MultiPage from '@/components/MultiPage';

export default {
  name: "BasePage",
  components: {
    DynamicContent,
    MultiPage
  },
  props: {
    link: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      data: null,
    }
  },
  watch: {
    page(n, o) {
      console.log("setting cur page", n, o);
      this.setCurPage(n);
      setTimeout(() => this.scrollToAnchor(), 500);
    }
  },
  computed: {
    page() {
      if (this.pages && this.pages.length > 0) {
        if (this.$route.path === "/") {
          let current = this.pages.find(p => p.id == this.settings.homepage);
          if (!current) current = this.pages.find(p => p.slug == "willkommen");
          return current;
        } else {
          let route = this.$route.path;
          route = route.replace("/" + this.$route.params.slug, "");
          let current = this.pages.find(p => '/' + p.slug == route.replace(/\/$/, ""));
          return current;
        }
      }
      return null;
    },
    base() {
      let route = this.$route.path;
      route = route.replace("/" + this.$route.params.slug, "");
      return route;
    },
    multipage() {
      return (this.page && this.page.pages && this.page.pages.length > 0);
    },
    ...mapGetters([
      "pages",
      "settings",
      "defaultKeywords"
    ])
  },
  metaInfo() {
    let keywords = this.defaultKeywords;
    if (this.page) {
      keywords = this.page.title.rendered;
    }
    keywords = [keywords, this.defaultKeywords];
      let title = (this.page && this.page.title && this.page.rendered) 
      ? "André Siebruck und Werbemittel: " + this.page.title.rendered + " - Wir stehen für Qualität"
      : "André Siebruck und Werbemittel - Wir stehen für Qualität";

    let excerpt = "Wir sind ein kleines Familienunternehmen in Höchst, das sich seit 2000 auf Siebdruck spezialisiert hat.";
    return {
      title: title,
      meta: [
        { vmid: "keywords", name: "keywords", content: keywords.join(",")},
        {
          vmid: "description",
          name: "description",
          content: "André Siebruck und Werbemittel: " + excerpt
        }
      ]
    };
  },
  methods: {
    scrollToAnchor() {
      let anchor = location.hash;
      if (!anchor) return;
      let a = anchor.replace("#", "");
      let element = document.querySelector("a[name=\"" + a + "\"]");
      if (element) {
        element.scrollIntoView();
      }
    },
    ...mapActions([
      'setCurPage'
    ])
  }
};
</script>

<style lang="scss" scoped>
.base-page {
  @media all and (max-width: 1100px) {
    width: 100%;
  }
  ::v-deep {
    h2 {
      /*font-size: 20px;*/
      font-size: 22px;
      font-weight: bold;
    }
    p, li {
      /*line-height: 24px;*/
      /*font-size: 16px;*/
      font-size: 18px;
      line-height: 27px;
    }
    .wp-block-image {
      figure {
        figcaption {
          text-transform: uppercase;
          font-weight: bold;
          margin: 20px 0;
          font-size: 18px;
          color: var(--brown);
        }
      }
    }

    .under-construction {
      color: var(--red) !important;
      margin-top: 40px;
    }
  }
}
</style>
