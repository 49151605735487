<template>
  <div class="products-view">
    <div v-if="categories" class="categories-container">
      <router-link
        v-for="cat of categories"
        :key="'cat-' + cat.slug"
        class="cat"
        :class="{ active: $route.params.slug == cat.slug }"
        :to="'/produkte/' + cat.slug"
      >
        <div class="icon">
          <img v-if="cat.icon" :src="cat.icon" />
          <img v-else src="@/assets/category-placeholder.png" />
        </div>
        <div class="name">{{ cat.name }}</div>
      </router-link>
    </div>
    <div id="nav-container" class="product-nav-container">
      <p v-if="activeCategory" class="head">Übersicht der meistverkauften Produkte zum Thema {{ activeCategory }}</p>
      <p v-else class="head">Übersicht über unsere meistverkaufen Produkte</p>
      <p class="inquire">Weitere Auswahl und Sondergrößen auf <a :href="'mailto:' + inquiryEmail + '?subject=Anfrage'">Anfrage</a></p>
      <div class="nav-buttons-container">
        <div @click="prevCat" class="cat-nav-btn"><span class="icon">&lt;</span> vorherige Kategorie</div>
        <div @click="nextCat" class="cat-nav-btn">nächste Kategorie<span class="icon">&gt;</span> </div>
      </div>
    </div>
    <div v-if="filteredProducts" id="products-container" class="products-container">
      <transition-group name="list" tag="div" mode="out-in">
        <Product
          v-for="p of filteredProducts"
          :key="'product-' + p.id"
          :product="p"
        />
      </transition-group>
      <div class="product-nav-container bottom">
        <p v-if="activeCategory" class="head">Übersicht der meistverkauften Produkte zum Thema {{ activeCategory }}</p>
        <p v-else class="head">Übersicht über unsere meistverkaufen Produkte</p>
        <p class="inquire">Weitere Auswahl und Sondergrößen auf <a :href="'mailto:' + inquiryEmail + '?subject=Anfrage'">Anfrage</a></p>
        <div class="nav-buttons-container">
          <div @click="prevCat" class="cat-nav-btn"><span class="icon">&lt;</span> vorherige Kategorie</div>
          <div @click="nextCat" class="cat-nav-btn">nächste Kategorie<span class="icon">&gt;</span> </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Product from '@/components/Product';

export default {
  name: "Products",
  components: {
    Product
  },
  computed: {
    categories() {
      if (!this.products || this.products.length <= 0) return null;
      let cats = {};
      for (let p of this.products) {
        if (p.tags) {
          for (let t of p.tags) {
            if (cats.hasOwnProperty(t.slug)) {
              cats[t.slug].count++;
              continue;
            }
            cats[t.slug] = { ...t };
            cats[t.slug].count = 1;
          }
        }
      }
      cats = Object.values(cats);
      cats.sort((a, b) => (parseInt(a.sort_order, 10) > parseInt(b.sort_order, 10)) ? 1 : -1);
      return cats;
      
    },
    activeCategory() {
      if (!this.$route.params.slug) return "";
      if (!this.categories) return this.$route.params.slug;
      if (!this.categories.hasOwnProperty(this.$route.params.slug)) return this.$route.params.slug;
      return this.categories[this.$route.params.slug].name;
    },
    randomProducts() {
      if (!this.products || this.products.length == 0) return [];
      let selection = [ ...this.products ];
      return selection.sort(() => (Math.random() > 0.5 ? 1 : -1)).slice(0, 10)
    },
    filteredProducts() {
      if (!this.$route.params.slug) return this.randomProducts;
      let curCat = this.categories.find(c => c.slug == this.$route.params.slug)
      if (!curCat) return this.products;
      return this.products.filter(p => {
        if (p.tags) {
          return p.tags.map(t => t.term_id).includes(curCat.term_id);
        }
      })
    },
    ...mapGetters(["products", "inquiryEmail"])
  },
  metaInfo() {
    let catMeta = this.categories.map(c => c.name);
    let filteredProductMeta = this.filteredProducts.map(p => p.title);
    let productMeta = this.products
      .filter(p => !filteredProductMeta.includes(p.title))
      .map(p => p.title);
    let meta = [...catMeta, ...filteredProductMeta, ...productMeta];
    return {
      title: "André Siebruck: Produkte",
      meta: [
        { vmid: "keywords", name: "keywords", content: meta.join(",") },
        {
          vmid: "description",
          name: "description",
          content: "André Siebruck und Werbemittel: Hier finden Sie einen Auszug aus unserer Produktauswahl."
        }
      ]
    }
  },
  watch: {
    "$route.params.slug": {
      handler: function() {
        let element = document.getElementById("nav-container");
        if (!element) return;
        element.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    nextCat() {
      let curIndex = this.categories.findIndex(c => c.slug == this.$route.params.slug);
      let nextIndex = (curIndex + 1 < this.categories.length) ? curIndex + 1 : 0;
      let nextCat = this.categories[nextIndex];
      if (curIndex != nextIndex) {
        this.$router.push("/produkte/" + nextCat.slug);
      }
    },
    prevCat() {
      let curIndex = this.categories.findIndex(c => c.slug == this.$route.params.slug);
      let nextIndex = (curIndex - 1 >= 0) ? curIndex - 1 : this.categories.length - 1;
      let nextCat = this.categories[nextIndex];
      if (curIndex != nextIndex) {
        this.$router.push("/produkte/" + nextCat.slug);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.products-view {
  .categories-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media all and (max-width: 640px) {
      justify-content: center;
    }
    @media all and (max-width: 400px) {
      justify-content: space-evenly;
    }

    .cat {
      margin-right: 30px;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      @media all and (max-width: 768px) {
        margin-right: 20px;
      }

      @media all and (max-width: 640px) {
        margin-right: 10px;
      }

      @media all and (max-width: 400px) {
        margin-right: 0;
      }

      .icon {
        width: 260px;
        height: 200px;
        background: var(--red);
        transition: background 0.2s ease-in-out;
        will-change: background;

        @media all and (max-width: 1070px) {
          width: 200px;
          height: 160px;
        }

        @media all and (max-width: 768px) {
          width: 180px;
          height: 150px;
          img {
            width: 100%;
            height: auto;
          }
        }

        @media all and (max-width: 720px) {
          width: 150px;
          height: 100px;
        }

        @media all and (max-width: 700px) {
          width: 130px;
          height: 100px;
        }
      }

      .name {
        font-size: 20px;
        text-transform: uppercase;
        margin-top: 5px;
        font-weight: 500;
        color: var(--brown);
        @media all and (max-width: 720px) {
          font-size: 16px;
        }
        @media all and (max-width: 700px) {
          font-size: 14px;
        }
      }

      &.active, &:hover {
        .icon {
          background: grey;
        }
      }
    }
  }
  
  .products-container {
    .product {
      margin-bottom: 40px;

      .title {
        color: var(--brown);
        font-size: 22px;
        text-transform: uppercase;
        font-weight: bold;
      }
      ::v-deep {
        .wp-block-columns.has-2-columns {
          .wp-block-column {
            &:first-child {
              width: 560px;
              img {
                width: 100%;
                height: auto;
              }
            }
            &:nth-child(2) {
              ul {
                padding: 0;
                li {
                  margin-bottom: 5px;

                  &:before {
                    display: none;
                  }
                }
              }
              p {
                margin: 0 0 10px 0;
              }
            }
          }
        }
      }
    }
  }

  .product-nav-container {
    border-top: 2px solid var(--red);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    margin: 0 0 60px 0;

    &.bottom {
      border: none;
    }

    .head {
      font-weight: 500;
      font-size: 20px;
      color: var(--brown);
      text-transform: uppercase;
      margin: 0 0 10px 0;
    }

    .inquire {
      margin: 0 0 20px 0;
    }

  }

  .nav-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media all and (max-width: 400px) {
      justify-content: space-between;
      flex-direction: column-reverse;
    }

    .cat-nav-btn {
      padding: 5px;
      margin: 0 10px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .icon {
        color: var(--red);
        font-size: 2em;
        display: inline-block;
        margin: 0 10px;
      }

      @media all and (max-width: 400px) {
        margin: 0;
        .icon {
          margin: 0 2px;
        }
      }
    }
  }

  .list-enter-active, .list-leave-active {
    transition: all 0.3s;
  }
  .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>
