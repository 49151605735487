import Vue from "vue";
import Meta from "vue-meta";
import Router from "vue-router";
import BasePage from "@/views/BasePage";
import Products from "@/views/Products";
import References from "@/views/References";

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from) {
    if (from.name == "products" && to.name == "category") return;
    if (from.name == "category" && to.name == "category") return;
    let element = null;
    if (to.params && to.params.slug) {
      element = document.getElementById("sub-page-" + to.params.slug);
    }
    if (!element) {
      element = document.getElementById("app");
    }
    element.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: BasePage,
      meta: { title: "Willkommen", hideSpecialLink: true }
    },
    {
      path: "/aktion",
      name: "aktion",
      component: BasePage,
      meta: { title: "Aktion", hideSpecialLink: true }
    },
    {
      path: "/produkte",
      name: "products",
      component: Products,
      meta: { title: "Produkte" }
    },
    {
      path: "/produkte/:slug",
      name: "category",
      component: Products,
      meta: { title: "Produkte" }
    },
    {
      path: "/referenzen",
      name: "references",
      component: References,
      meta: { title: "Referenzen" }
    },
    {
      path: "/wissenswertes",
      name: "good-to-know",
      component: BasePage,
      meta: { title: "Wissenswertes" }
    },
    {
      path: "/wissenswertes/:slug",
      name: "good-to-know-sub-page",
      component: BasePage,
      meta: { title: "Wissenswertes" }
    },
    {
      path: "/:route/:slug?",
      component: BasePage,
    },
    {
      path: "*",
      component: BasePage,
    }
  ]
});

//router.beforeEach((to, from, next) => {
  //next();
//})

export default router;
