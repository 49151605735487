<template>
  <div class="multipage-view">
    <DynamicContent v-if="mainPage" :content="mainPage.content.rendered" />
    <div class="page-nav-container">
      <div
        v-for="(page, i) of pages"
        :class="{ active: index == i }"
        class="page"
        @click="gotoPage(page)"
        :key="page.ID + '-' + i"
      >
        <div class="thumb">
          <img v-if="page.thumbnail" :src="page.thumbnail" alt="Seitenthumbnail"/>
        </div>
        <div class="title">{{ page.post_title }}</div>
        <div class="excerpt">{{ page.post_excerpt }}</div>
      </div>
    </div>
    <hr />
    <a :id="'sub-page-' + subPage" :name="subPage" />
    <DynamicContent
      :content="curPage.post_content"
      :key="'sub-page-' + curPage.ID"
    />
  </div>
</template>

<script>
import DynamicContent from '@/components/DynamicContent';
import { mapGetters } from "vuex";

export default {
  name: "MultiPage",
  components: {
    DynamicContent
  },
  props: {
    pages: Array,
    base: String,
    subPage: String,
    mainPage: Object
  },
  data() {
    return {
      index: 0
    }
  },
  mounted() {
    if (this.subPage) {
      let index = this.pages.findIndex(p => p.post_name == this.subPage);
      if (index > -1) this.index = index;
    }
  },
  computed: {
    curPage() {
      if (this.pages && this.index < this.pages.length) return this.pages[this.index];
      return this.pages[0];
    },
    ...mapGetters(["defaultKeywords"])
  },
  metaInfo() {
    let keywords = this.pages.map(p => p.post_title);
    keywords = [...keywords, this.defaultKeywords];
    let excerpt = this.mainPage.excerpt.rendered.replace(/(<([^>]+)>)/ig,"").replace(/(\[([^\[]+)\])/ig,"").trim()
    if (excerpt === "") excerpt = "Wir sind ein kleines Familienunternehmen in Höchst, das sich seit 2000 auf Siebdruck spezialisiert hat.";
    return {
      title: "André Siebruck und Werbemittel: " + this.mainPage.title.rendered + " - Wir stehen für Qualität",
      meta: [
        { vmid: "keywords", name: "keywords", content: keywords.join(",") },
        {
          vmid: "description",
          name: "description",
          content: "André Siebruck und Werbemittel: " + excerpt
        }
      ]
    };
  },
  methods: {
    gotoPage(page) {
      let index = this.pages.findIndex(p => p.post_name == page.post_name);
      if (index > -1) {
        this.index = index;
        this.$router.push(this.base + "/" + this.pages[index].post_name);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.multipage-view {
  .page-nav-container {
    display: flex;
    flex-direction: row;

    .page {
      text-align: center;
      cursor: pointer;
      flex: 1 1;

      @media all and (max-width: 768px) {
        &:not(:first-child), &:not(:last-child) {
          margin: 0 10px;
        }
      }

      img {
        transition: filter 0.3s ease-in-out;
      }

      &.active {
        img {
          filter: grayscale(100%);
        }
      }
      
      .title {
        color: var(--brown);
        font-size: 1.5rem;
        font-weight: 500;
        margin: 10px 0;
      }

      .excerpt {
        line-height: 1.5rem;
      }
    }

    @media all and (max-width: 600px) {
      flex-direction: column;
      flex-wrap: wrap;

      .page {
        display: flex;
        flex: 1 0;
        flex-wrap: wrap;
        margin-bottom: 20px !important;
        align-items: center;

        .thumb {
          width: 60px;
        }
        .title {
          width: calc(100% - 60px);
          text-align: left;
          padding-left: 20px;
        }
        .excerpt {
          text-align: left;
          margin-top: 5px;
        }
      }
    }

  }
}
</style>
