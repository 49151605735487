<template>
  <div v-if="product" class="product">
    <h2 :class="[{ placeholder: isPlaceholder }]">{{ product.title }}</h2>
    <template v-if="!isPlaceholder">
      <div v-if="!isPlaceholder" class="product-content">
        <div class="image">
          <img v-if="product.image" :src="product.image" :alt="'Produktbild für: ' + product.title" />
        </div>
        <div class="data">
          <p v-if="product.description">{{ product.description }}</p>
          <ul v-if="product.data && product.data.length > 0">
            <li
              v-for="prop of product.data"
              :key="'p-' + product.id + '-prop-' + prop.label"
            >
              <strong>{{ prop.label }}: </strong>
              <DynamicContent
                :content="prop.value"
                :inline="true"
              />
            </li>
            <li
              v-if="inquiryEmail">
              <a
                :href="
                'mailto:' +
                inquiryEmail +
                '?subject=Anfrage: ' +
                encodeURIComponent(product.title)
                "
                >Anfrage stellen</a>
            </li>
          </ul>
        </div>
      </div>
      <div
        v-if="product.gallery && product.gallery.length > 0"
        class="product-gallery"
      >
        <img v-for="img of product.gallery" :src="img" :key="img" :alt="'Galeriebild für: ' + product.title"/>
      </div>
    </template>
    <div v-else class="placeholder">
      <p>{{ product.description }}</p>
      <p v-if="inquiryEmail">
        <a
          :href="'mailto:' +
          inquiryEmail +
          '?subject=Anfrage: ' +
          encodeURIComponent(product.title)
          "
          >Anfrage stellen</a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DynamicContent from "@/components/DynamicContent";

export default {
  name: "Product",
  components: { DynamicContent },
  props: {
    product: Object
  },
  computed: {
    isPlaceholder() {
      if (!this.product) return false;
      if (!this.product.data) return false;
      return (!this.product.image && this.product.data.length == 0);
    },
    ...mapGetters([ "inquiryEmail" ])
  }
}
</script>

<style lang="scss" scoped>
.product {
  .placeholder {
    text-align: center;
    font-size: 2rem;
  }

  h2.placeholder {
    text-align: center;
  }

  .product-content {
    display: flex;
    flex-direction: row;

    @media all and (max-width: 768px) {
      flex-direction: column;
    }

    .image {
      width: 560px;
      max-width: 560px;
      flex: 1 0 auto;
      img {
        width: 100%;
      }
      @media all and (max-width: 560px) {
        width: 100%;
      }
    }
    .data {
      padding-left: 30px;
      @media all and (max-width: 768px) {
        padding-left: 0;
      }

      p {
        margin: 0 0 20px 0;
      }
      ul {
        margin: 0;
        padding: 0;

        li {
          padding: 0;
          margin: 4px 0;

          &:before {
            content: none;
          }
        }
      }
    }
  }

  .product-gallery {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;

    img {
      width: 560px;
      max-width: 560px;
      height: auto;
      margin-bottom: 30px;
      &:nth-child(odd) {
        margin-right: 30px;
      }

      @media all and (max-width: 560px) {
        width: 100%;
      }
    }
  }
}
</style>
