import Vue from "vue";
import Vuex from "vuex";
import pages from "./pages.json";
let curPage = pages.find(p => p.slug == "willkommen");

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    footerItems: { },
    pages: pages,
    settings: {},
    curPage: curPage,
    menuActive: false,
    products: [],
    references: [],
    defaultKeywords: "andre, andré, siebdruck, druck, lokal, druckerei, höchst, hoechst, grünau, gruenau, nussbaumer, nußbaumer, bregenz, vorarlberg, österreich, austria, qualität, bestellen, bedrucken, daten, persönlich, familienunternehmen, expertise, stick, sublimiert, aufkleber, t-shirt, shirt, shirts, caps, kappen, schirme, regenschirm, regenschirme, feuerzeuge, feuerzeug, polo, polos, sweater, jacke, jacken, arbeitskleidung, taschen, beutel, kugelschreiber, bier, etiketten, fruit of the loom"
  },
  mutations: {
    SET_PAGES(state, payload) {
      state.pages = payload;
    },
    SET_SETTINGS(state, payload) {
      state.settings = payload;
    },
    SET_PRODUCTS(state, payload) {
      state.products = payload;
    },
    SET_REFERENCES(state, payload) {
      state.references = payload;
    },
    SET_CUR_PAGE(state, payload) {
      state.curPage = payload;
    },
    SET_MENU_ACTIVE(state, payload) {
      state.menuActive = payload;
    }
  },
  actions: {
    async loadPages({ commit }) {
      let result = await fetch(process.env.VUE_APP_WP_API_URL + '/wp-json/wp/v2/pages?per_page=100');
      let data = await result.json();
      commit('SET_PAGES', data);
    },
    async loadProducts({ commit }) {
      let result = await fetch(process.env.VUE_APP_WP_API_URL + '/wp-json/andre/v1/products');
      let data = await result.json();
      commit('SET_PRODUCTS', data);
    },
    async loadReferences({ commit }) {
      let result = await fetch(process.env.VUE_APP_WP_API_URL + '/wp-json/wp/v2/andre-references?per_page=100');
      let data = await result.json();
      commit('SET_REFERENCES', data);
    },
    async loadSettings({ commit }) {
      let result = await fetch(process.env.VUE_APP_WP_API_URL + '/wp-json/andre/v1/settings');
      let data = await result.json();
      commit('SET_SETTINGS', data);
    },
    setCurPage({ commit }, page) {
      commit('SET_CUR_PAGE', page);
    },
    toggleMenu({ commit, state }) {
      commit('SET_MENU_ACTIVE', !state.menuActive);
    }
  },
  getters: {
    footerItems(state) {
      if (!state.settings)
        return 0;
      if (!state.settings.footer)
        return 0;
      if (!state.settings.footer.links)
        return 0;

      return state.settings.footer.links;
    },
    footerColumns(state) {
      if (!state.settings)
        return 0;
      if (!state.settings.footer)
        return 0;
      if (!state.settings.footer.links)
        return 0;

      if (state.settings.footer.links.length > 0) {
        let columns = [];
        state.settings.footer.links.forEach(item => {
          if (!columns.includes(item.column)) columns.push(item.column);
        });
        return columns.length;
      }
      return 0;
    },
    inquiryEmail: state => state.settings.inquiry_email || null,
    settings(state) {
      return state.settings;
    },
    pages(state) {
      return state.pages;
    },
    curPage(state) {
      return state.curPage;
    },
    products: state => state.products,
    references: state => state.references,
    menuActive(state) {
      return state.menuActive;
    },
    menuItems(state) {
      if (!state.settings)
        return 0;
      if (!state.settings.menu)
        return 0;
      if (!state.settings.menu.links)
        return 0;

      return state.settings.menu.links;
    },
    defaultKeywords: state => state.defaultKeywords
  }
});
