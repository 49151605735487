<template>
<div class="footer">
  <div class="content default">
    <template v-if="footerItems.length > 0">
      <template v-if="footerColumns > 0">
         <ul v-for="col in footerColumns" :key="col">
          <li v-for="item in footerItems.filter(f => f.column == col)" :key="item.title">
            <router-link :to="'/' + item.name + item.anchor">{{ item.title }}</router-link>
          </li>
        </ul> 
      </template>
    </template>
  </div>
  <div class="content mobile">
    <template v-if="footerItems.length > 0">
      <template v-if="footerColumns > 0">
        <template v-for="col in [0, 1]">
           <ul v-if="col == 0" :key="'mobile-' + col">
            <li v-for="item in footerItems.filter(f => parseInt(f.column, 10) < footerColumns)" :key="item.title">
              <router-link :to="'/' + item.name + item.anchor">{{ item.title }}</router-link>
            </li>
          </ul>
          <ul v-else :key="'mobile-' + col">
            <li v-for="item in footerItems.filter(f => parseInt(f.column, 10) == footerColumns)" :key="item.title">
              <router-link :to="'/' + item.name + item.anchor">{{ item.title }}</router-link>
            </li>
          </ul> 
        </template>
      </template>
    </template>
  </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'TheFooter',
  computed: {
    ...mapGetters([
      'footerItems',
      'footerColumns'
    ])
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 1240px;

  .content {
    display: flex;
    padding: 0 0 0 20px;
    margin: 50px 0 100px 0;
    width: 80%;
    text-transform: uppercase;

    &.mobile {
      display: none;
      @media all and (max-width: 480px) {
        display: flex;
      }
    }

    &.default {
      @media all and (max-width: 480px) {
        display: none;
      }
    }

    @media all and (max-width: 480px) {
      flex-wrap: wrap;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      @media all and (max-width: 480px) {
        width: 50%;
      }

      &:not(:first-child) {
        margin-left: 160px;
        @media all and (max-width: 480px) {
          margin: 0;
        }
      }
      &:last-child {
        margin-left: auto;
      }

      li {
        margin: 0;
        &::before {
          display: none;
        }
        a {
          color: #fff;
          font-weight: normal;
          &:hover {
          }
        }
      }
    }

    @media all and (max-width: 1000px) {
      ul:nth-child(2) {
        margin-left: 40px;
      }
    }

    @media all and (max-width: 700px) {
      width: 100%;

      ul:nth-child(2) {
        margin-left: 20px;
      }
      ul:last-child {
        margin: 0;
        margin-left: auto;
      }
    }

  }
}
</style>
